<template>
  <div class="section">

    <Table :result="result" :get-data="getContacts" @filter="getFilter" />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import {
  VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import HeaderPage from '@/components/HeaderPage.vue'
import Table from '@/components/ContactUs/Table.vue'

export default {
  title() {
    return 'Contact Us'
  },
  components: {
    HeaderPage,
    Table,
  },
  computed: {
    ...mapState({
      contacts: state => state.contact_us.contacts,
    }),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: {
        name: '',
        description: '',
        logo: '',
      },
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      response: Object,
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getContacts()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getContacts()
  },
  methods: {
    ...mapActions('contact_us', ['getData', 'postData', 'deleteData', 'showData']),
    getPayload(value) {
      this.formPayload = value
    },
    getFilter(value) {
      this.filter = value
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
      }
      this.validations = ''
    },
    async getContacts(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      await this.getData({
        params: queryParams,
      })
      this.result = this.contacts
      this.currentPage = this.contacts.current_page
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.master-toko {
  height: 100vh
}
</style>
