<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12">
        <div class="d-flex px-1">
          <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'" />
          <div class="search__input w-100 pl-2 pr-0 mr-0">
            <b-input-group>
              <b-form-input v-model="filter.search" placeholder="" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th v-for="column, index in columns" :key="index">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.question }}</td>
              <td>{{ item.created_at }}</td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton,
} from 'bootstrap-vue'

// import ModalBank from '@/components/Master/Bank/Modal/ModalBank.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BFormSelect,
    // ModalBank,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nama',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'No. Handphone',
          field: 'phone',
        },
        {
          label: 'Pertanyaan',
          field: 'question',
        },
        {
          label: 'Tanggal',
          field: 'created_at',
        },
      ],
      rows: [],
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'desc',
        per_page: 10,
      },
    }
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  created() {

  },
  mounted() {
    // this.items = this.result.data
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.section__contact--us {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;

    .vgt-table.bordered {
      thead th:last-child {
        text-align: left !important;
      }
    }
  }
}
</style>
